import React, { memo } from 'react'
import DOMPurify from 'dompurify'
import { splitByMergeTag, splitMergeTag } from '~/utils/utilities'

interface IProps {
  text: string
}

const PreviewMergeTagText = ({ text }: IProps): React.ReactElement | null => {
  if (!text) {
    return null
  }

  const splittedByMergeTag = splitByMergeTag(text)

  const htmlContent = splittedByMergeTag
    .map((part, index) => {
      if (part.includes('{{')) {
        const mergeTag = splitMergeTag(part)
        return `<span class="preview__tag">${mergeTag.tag}</span>`
      }
      return part
    })
    .join('')

  const sanitizedHtmlContent = DOMPurify.sanitize(htmlContent)

  return (
    <div
      className="preview__text"
      dangerouslySetInnerHTML={{ __html: sanitizedHtmlContent as string }}
    />
  )
}

const PreviewMergeTagTextMemo = memo(PreviewMergeTagText)
export default PreviewMergeTagTextMemo
