import React, { useEffect, useRef, useState } from 'react'
import { Editor } from '@tinymce/tinymce-react'
import { splitMergeTag } from '~/utils/utilities'
import { useStore } from '~/dataStore'

interface RichTextEditorProps {
  mergeTags: string[]
  initialValue: string
  placeholder: string
  onChange: (value: string) => void
  disabled: boolean
}

const RichTextEditor = ({
  mergeTags,
  initialValue,
  placeholder,
  onChange,
  disabled
}: RichTextEditorProps) => {
  const editorRef = useRef(null)
  const [editorKey, setEditorKey] = useState(0)
  const [content, setContent] = useState(initialValue)
  const {
    app: { currentAdmin }
  } = useStore()

  useEffect(() => {
    setContent(initialValue)
  }, [initialValue])

  useEffect(() => {
    setEditorKey((prevKey) => prevKey + 1)
  }, [mergeTags])

  const handleEditorInit = (evt, editor) => {
    editor.on('click', function (event) {
      const { target } = event
      const mergeTag = splitMergeTag(target.textContent)
      if (target.classList.contains('mce-mergetag')) {
        editor.windowManager.open({
          title: 'Set Default Value',
          initialData: {
            defaultValue: mergeTag?.default || ''
          },
          body: {
            type: 'panel',
            items: [
              {
                type: 'input',
                name: 'defaultValue',
                label: 'Default Value'
              }
            ]
          },
          buttons: [
            {
              type: 'cancel',
              text: 'Cancel'
            },
            {
              type: 'submit',
              text: 'Save',
              primary: true
            }
          ],
          onSubmit(dialog) {
            const data = dialog.getData()
            const newValue = data.defaultValue
            target.innerHTML = `{{${mergeTag.tag} | default: ${newValue}}}`
            dialog.close()
          }
        })
      }
    })
  }

  return (
    <div
      className="py-3"
      onDragEnter={(e) => e.stopPropagation()}
      draggable
      onDragStart={(e) => {
        e.stopPropagation()
        e.preventDefault()
      }}>
      <Editor
        key={editorKey}
        onInit={(_evt, editor) => {
          editorRef.current = editor
          handleEditorInit(_evt, editor)
        }}
        apiKey={currentAdmin?.tinymcApiKey}
        onEditorChange={(content) => {
          setContent(content)
          onChange(content)
        }}
        value={content}
        disabled={disabled}
        init={{
          content_style:
            '.mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before { color: #96aac6 !important; }',
          plugins: ['emoticons', 'lists', 'mergetags'],
          menubar: 'edit insert view',
          placeholder,
          branding: false,
          toolbar:
            'bold italic underline | mergetags | numlist bullist | emoticons',
          mergetags_prefix: '{{',
          mergetags_suffix: '}}',
          mergetags_list: mergeTags?.map((tag) => ({
            value: tag,
            title: tag
          }))
        }}
      />
    </div>
  )
}

export default RichTextEditor
